import { useSetRecoilState, useResetRecoilState } from 'recoil'
import { H2LFieldJson, MultiPolygon, Polygon } from '../types'
import { useFetchWrapper } from '../_helpers'
import { bedCollectionsAtom, h2lFieldJsonAtom } from '../_state'

export { useCropActions }

function useCropActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()
    const setBedCollections = useSetRecoilState(bedCollectionsAtom)

    return {
        getCropByIdWithBedCollections,
        getCropByIdWithTulibBeds,
        detectTreatables,
        updateCropTulipBeds,
        bufferArea,
        resetBedCollections: useResetRecoilState(bedCollectionsAtom),
        resetTulipBeds: useResetRecoilState(h2lFieldJsonAtom),
    }

    function getCropByIdWithBedCollections(cropID: number) {
        return fetchWrapper.get(`${baseUrl}/crops/${cropID}/raw_crop`).then(setBedCollections)
    }

    function getCropByIdWithTulibBeds(cropID: number) {
        return fetchWrapper.get(`${baseUrl}/crops/${cropID}/beds`)
    }

    function detectTreatables(cropID: number) {
        return fetchWrapper.get(`${baseUrl}/crops/${cropID}/detect_treatables`)
    }

    function updateCropTulipBeds(cropID: number, field: H2LFieldJson) {
        return fetchWrapper.post(`${baseUrl}/crops/${cropID}/tulip_beds`, field)
    }

    function bufferArea(area: Polygon | MultiPolygon, distance: number): Promise<Polygon | MultiPolygon> {
        return fetchWrapper.post(`${baseUrl}/crops/buffer_area`, {
            area: area,
            distance: distance,
        })
    }
}
