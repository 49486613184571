import { Stack, Box, Button } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'

export function MenuEditorChoice() {
    const location = useLocation()

    const menuItems = [
        { label: 'Draw New Boundary', to: 'boundary-draw' },
        { label: 'Edit Boundary', to: 'boundary-edit' },
        { label: 'Edit Planted Area', to: 'planted-area-edit' },
        { label: 'Skip Rows', to: 'skip-rows' },
        { label: 'Cultivar Matching', to: 'cultivar-matching' },
    ]

    return (
        <Box
            sx={{
                position: 'absolute',
                bottom: '2%',
                right: '2%',
            }}
        >
            <Stack
                component='nav'
                aria-label='Device settings'
                sx={{
                    bgcolor: 'background.paper',

                    borderRadius: '8px',
                    padding: '16px',
                }}
            >
                {menuItems.map((item) => (
                    <Button
                        key={item.to}
                        component={Link}
                        to={item.to}
                        variant={location.pathname.includes(item.to) ? 'contained' : 'outlined'}
                        color='primary'
                        sx={{ marginBottom: '8px' }}
                    >
                        {item.label}
                    </Button>
                ))}
            </Stack>
        </Box>
    )
}
