import { lazy, Suspense } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Map } from '..'
import { followPathStateSelector, machineTaskStatus, taskManagerAtom } from '../../_state'
import { TaskManager } from './TaskManager'
import { Stack, Typography, Box, Fab } from '@mui/material'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import { styles } from '../../styles'
import { CropTask } from '../../gmap/CropTask'
import { RobotIcon } from '../RobotIcon'
import { MachineInstruction } from '../../machines/MachineInstruction'
const SelectorRemoteIcon = lazy(() => import('../icons/SelectorRemoteIcon'))
const MoveInRangeIcon = lazy(() => import('../icons/MoveInRangeIcon'))

export const NavigationTab = () => {
    const [taskManagerOpen, setTaskManager] = useRecoilState(taskManagerAtom)
    const machineTaskState = useRecoilValue(machineTaskStatus)
    const followPathState = useRecoilValue(followPathStateSelector)

    return (
        <Box sx={[styles.NavigationTab.OuterBox]}>
            <Box sx={[styles.NavigationTab.MapBox]}>
                <Map>
                    <CropTask />
                    <RobotIcon />
                </Map>
                {!taskManagerOpen && (
                    <Fab
                        variant='extended'
                        onClick={() => setTaskManager('select')}
                        disabled={
                            machineTaskState === 'stop' ||
                            machineTaskState === 'paused' ||
                            machineTaskState === 'loading'
                        }
                        sx={[styles.NavigationTab.Fab]}
                    >
                        <FormatListBulletedIcon sx={{ m: 1 }} />
                        {taskManagerOpen ? 'Close' : 'Tasks'}
                    </Fab>
                )}
            </Box>
            {followPathState === 'OUTRANGE' && (
                <MachineInstruction
                    instruction='Machine out of range'
                    helpboxBody={
                        <Stack spacing={1}>
                            <Typography variant='body2' fontWeight='400' sx={{ whiteSpace: 'pre-line' }}>
                                {`Green arrow should be near to the blue arrow. 
                            
                            Use the manual control box to move and align
                            the machine (green arrow) with the closest task position (blue arrow). Match both the
                            distance and orientation.`}
                            </Typography>
                            <Suspense fallback={<div>Loading...</div>}>
                                <MoveInRangeIcon />
                            </Suspense>
                        </Stack>
                    }
                    color='#ed6c02'
                />
            )}
            {followPathState === 'INRANGE' && (
                <MachineInstruction
                    instruction='Press AUTO START'
                    helpboxBody={
                        <Stack spacing={1}>
                            <Typography variant='body2' fontWeight='400' sx={{ whiteSpace: 'pre-line' }}>
                                {`The machine is ready to start the task, press the AUTOSTART button on the manual
                                control box and it will start driving.`}
                            </Typography>
                            <Suspense fallback={<div>Loading...</div>}>
                                <SelectorRemoteIcon />
                            </Suspense>
                        </Stack>
                    }
                    color={'#0288D1'}
                />
            )}
            {taskManagerOpen && (
                <Box sx={[styles.NavigationTab.TaskManagerBox]}>
                    <TaskManager />
                </Box>
            )}
        </Box>
    )
}
