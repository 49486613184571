import React from 'react'
import { useAlertActions } from '../_actions'
import GrowShrinkComponent from './GrowShrinkComponent'

interface PlantedAreaEditProps {
    plantedArea: google.maps.Data
}

export function PlantedAreaEdit(props: PlantedAreaEditProps) {
    const alert = useAlertActions()

    React.useEffect(() => {
        alert.info('You can change the planted-area by dragging the white circles')
        props.plantedArea.forEach((f) => {
            props.plantedArea.overrideStyle(f, {
                editable: true,
            })
        })
        return () => {
            props.plantedArea.forEach((f) => {
                props.plantedArea.overrideStyle(f, {
                    editable: false,
                })
            })
        }
    }, [])

    return <GrowShrinkComponent areaToAdjust={props.plantedArea} />
}
