import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { followPathCountDownSelector, machineTaskStatus } from '../../_state'
import { Typography, Box } from '@mui/material'
import { styled } from '@mui/system'

export { CountDownIndicator }

const SpinTypography = styled(Typography)({
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
    '&:hover': {
        animation: '$spin 1s linear infinite',
    },
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
})

function CountDownIndicator() {
    const [showETA, setShowETA] = useState(false)
    const seconds = useRecoilValue(followPathCountDownSelector)
    const machineTaskState = useRecoilValue(machineTaskStatus)
    if (machineTaskState === 'paused') return <Box sx={{ ml: 1 }}>PAUSED</Box>
    if (!seconds) return null

    const handleClick = () => setShowETA(!showETA)
    let value
    if (showETA) {
        value = new Date(new Date().getTime() + seconds * 1000).toLocaleString('nl', { hour12: false })
    } else {
        const d = Math.floor(seconds / (3600 * 24))
        const h = Math.floor((seconds % (3600 * 24)) / 3600)
        const m = Math.floor((seconds % 3600) / 60)
        const s = Math.floor(seconds % 60)

        const dDisplay = d > 0 ? d + 'd ' : ''
        const hDisplay = h > 0 ? h + 'h ' : ''
        const mDisplay = m > 0 ? m + 'm ' : ''
        const sDisplay = s > 0 ? s + 's' : ''
        value = dDisplay + hDisplay + mDisplay + sDisplay
    }

    return (
        <>
            <SpinTypography onClick={handleClick}>⏳ {value}</SpinTypography>
        </>
    )
}
