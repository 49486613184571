import React from 'react'
import { ConfirmDialog } from './'
import { useRecoilValue } from 'recoil'
import { machineTaskStatus, machineControlAtom, treatmentRunningSelector } from '../_state'
import { useJobActions } from '../_actions'
import { CameraSettings, DialogDataType } from '../types'
import IconButton from '@mui/material/IconButton'
import { GetBtnIcon } from './GetBtnIcon'
import { useTheme } from '@mui/material/styles'
import PauseCircleIcon from '@mui/icons-material/PauseCircle'
import { useGetBtnColor } from '../_helpers'

export { StartStopTaskButton }

function StartStopTaskButton({
    settings,
    handleModalClose,
}: {
    settings?: CameraSettings
    closeModal?: () => void
    handleModalClose?: () => void
}) {
    const theme = useTheme()
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [dialogData, setDialogData] = React.useState<DialogDataType | null>(null)

    const machineTaskState = useRecoilValue(machineTaskStatus)
    const machineControl = useRecoilValue(machineControlAtom)
    const treatmentRunning = useRecoilValue(treatmentRunningSelector)

    const jobActions = useJobActions()

    const handleButtonOnClick = () => {
        if (machineTaskState === 'start' || machineTaskState === 'resume') {
            handleStartJob()
            if (settings) handleModalClose?.()
        } else if (machineTaskState === 'paused') {
            handleUnpauseJob()
        } else if (machineTaskState === 'stop') {
            handleOpenDialog({
                dialogType: 'task-start-stop',
                title: 'Stop task?',
                message: 'Are you sure that you want to stop the currently running task?',
            })
        }
    }

    const handleOpenDialog = (dialogData: DialogDataType) => {
        setDialogData(dialogData)
        setDialogOpen(true)
    }

    const handleStartJob = () => jobActions.startJob(settings)
    const handleStopJob = () => jobActions.stopJob()
    const handlePauseJob = () => jobActions.pauseJob()
    const handleUnpauseJob = () => jobActions.unpauseJob()

    const handleDialogConfirm = () => {
        if (dialogData && dialogData['dialogType'] === 'task-start-stop') {
            machineTaskState === 'start' ? handleStartJob() : handleStopJob()
        }
    }

    return (
        <>
            {dialogData && (
                <ConfirmDialog
                    title={dialogData['title']}
                    open={dialogOpen}
                    setOpen={setDialogOpen}
                    onConfirm={handleDialogConfirm}
                    type={dialogData}
                >
                    {dialogData['message']}
                </ConfirmDialog>
            )}
            {machineTaskState === 'stop' && treatmentRunning && (
                <IconButton
                    key='pause-task'
                    sx={{
                        '& .MuiButton-startIcon': { marginRight: '0px', marginLeft: '0px' },
                        bgcolor: theme.palette.primary.dark,
                        ':hover': {
                            backgroundColor: theme.palette.info.main,
                        },
                    }}
                    onClick={() => handlePauseJob()}
                    size='large'
                >
                    <PauseCircleIcon />
                </IconButton>
            )}
            <IconButton
                key='start-stop-task'
                sx={{
                    '& .MuiButton-startIcon': { marginRight: '0px', marginLeft: '0px' },
                    bgcolor: useGetBtnColor(machineTaskState),
                    ':hover': {
                        backgroundColor: useGetBtnColor(machineTaskState),
                    },
                }}
                disabled={!machineControl || machineTaskState === 'select a task'}
                onClick={() => handleButtonOnClick()}
                size='large'
            >
                {GetBtnIcon(machineTaskState)}
            </IconButton>
        </>
    )
}
