import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import CircularProgress from '@mui/material/CircularProgress'

export const GetBtnIcon = (machineTaskState: string) => {
    if (machineTaskState === 'start') {
        return <PlayCircleFilledIcon />
    } else if (machineTaskState === 'stop') {
        return <StopCircleIcon />
    } else if (machineTaskState === 'resume' || machineTaskState === 'paused') {
        return <PlayCircleFilledIcon />
    } else if (machineTaskState === 'loading') {
        return <CircularProgress color='secondary' />
    } else if (machineTaskState === 'select a task') {
        return <PlayCircleFilledIcon />
    }
}
