import React from 'react'
import { useAlertActions } from '../_actions'
import GrowShrinkComponent from './GrowShrinkComponent'
import { useNavigate } from 'react-router-dom'

interface BoundaryEditProps {
    boundary: google.maps.Data
}

export function BoundaryEdit(props: BoundaryEditProps) {
    const alert = useAlertActions()
    const navigate = useNavigate()

    React.useEffect(() => {
        alert.info('You can change the boundary by dragging the white circles')
        let hasValidFeature = false
        props.boundary.forEach((f) => {
            hasValidFeature = true

            props.boundary.overrideStyle(f, {
                editable: true,
            })
        })
        if (!hasValidFeature) {
            navigate('../boundary-draw', { replace: true })
            return
        }
        return () => {
            props.boundary.forEach((f) => {
                props.boundary.overrideStyle(f, {
                    editable: false,
                })
            })
        }
    }, [])

    return <GrowShrinkComponent areaToAdjust={props.boundary} />
}
