import React, { useState } from 'react'
import { Button, Box, Modal, Slider, Typography, IconButton, CircularProgress } from '@mui/material'
import ExpandIcon from '@mui/icons-material/Expand'
import CompressIcon from '@mui/icons-material/Compress'
import CloseIcon from '@mui/icons-material/Close'
import { useAlertActions, useCropActions } from '../_actions'
import { useTheme } from '@mui/material/styles'

interface GrowShrinkComponentProps {
    areaToAdjust: google.maps.Data
}
const GrowShrinkComponent: React.FC<GrowShrinkComponentProps> = ({ areaToAdjust }) => {
    const { bufferArea } = useCropActions()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [sliderValue, setSliderValue] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const alerts = useAlertActions()
    const theme = useTheme()

    const handleModalOpen = () => setIsModalOpen(true)
    const handleModalClose = () => setIsModalOpen(false)

    const handleSliderChange = (_: Event, newValue: number | number[]) => {
        setSliderValue(newValue as number)
    }

    const handleAdjust = (adjustment: 'grow' | 'shrink'): void => {
        setIsLoading(true)
        areaToAdjust.toGeoJson(async (geoJson: any) => {
            try {
                if (geoJson.features[0] == undefined || geoJson.features[0].geometry.coordinates < 1) {
                    alerts.info('Area too small, start by drawing a new one')
                    return
                }
                const oldArea = geoJson.features[0]
                const adjustedGeometry = await bufferArea(
                    geoJson.features[0].geometry,
                    adjustment === 'grow' ? sliderValue : -sliderValue,
                )
                areaToAdjust.forEach((feature) => {
                    areaToAdjust.remove(feature)
                })
                oldArea.geometry = adjustedGeometry
                areaToAdjust.addGeoJson(oldArea)
                areaToAdjust.forEach((f) => {
                    areaToAdjust.overrideStyle(f, {
                        editable: true,
                    })
                })
            } catch (error) {
                console.log(error)
                alerts.error('Error while adjusting the area. Please try again later')
            } finally {
                setIsLoading(false)
            }
        })
    }
    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            sx={{
                position: 'absolute',
                top: '10%',
                right: '2%',
                border: '2px solid #ccc',
                borderRadius: '10px',
                padding: '10px',
                backgroundColor: '#fff',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                maxWidth: '300px',
            }}
        >
            {/* Shrink Button */}
            <Button
                onClick={() => handleAdjust('shrink')}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minWidth: '60px',
                    minHeight: '70px',
                    color: '#000',
                }}
                disabled={isLoading} // Disable the button while loading
            >
                {isLoading ? (
                    <CircularProgress size={24} />
                ) : (
                    <>
                        <CompressIcon fontSize='large' />
                        <Typography variant='caption'>Shrink</Typography>
                    </>
                )}
            </Button>

            {/* Middle Button */}
            <Button
                onClick={handleModalOpen}
                sx={{
                    border: '2px solid #666',
                    borderRadius: '8px',
                    minWidth: '80px',
                    height: '50px',
                    margin: '0 10px',
                    color: '#000',
                    fontWeight: 'bold',
                    fontSize: '16px',
                }}
            >
                {sliderValue}M
            </Button>

            {/* Grow Button */}
            <Button
                onClick={() => handleAdjust('grow')}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minWidth: '60px',
                    color: '#000',
                }}
                disabled={isLoading} // Disable the button while loading
            >
                {isLoading ? (
                    <CircularProgress size={24} />
                ) : (
                    <>
                        <ExpandIcon fontSize='large' />
                        <Typography variant='caption'>Grow</Typography>
                    </>
                )}
            </Button>

            {/* Modal with Slider */}
            <Modal open={isModalOpen} onClose={handleModalClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 300,
                        bgcolor: '#fff', // White background for the modal
                        border: '1px solid #ccc', // Light grey border for the modal
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Lighter shadow for a clean look
                        p: 4, // Increased padding to give space
                        borderRadius: 2,
                    }}
                >
                    <IconButton
                        onClick={handleModalClose}
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            color: '#333', // Dark color for close icon
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Slider
                        value={sliderValue}
                        min={0.01}
                        max={2}
                        step={0.01}
                        valueLabelDisplay='on'
                        onChange={handleSliderChange}
                        marks={[
                            { value: 0.01, label: '0.01 m' },
                            { value: 2, label: '2 m' },
                        ]}
                        sx={{
                            color: theme.palette.grey[600], // Grey color for the slider
                            '& .MuiSlider-thumb': {
                                backgroundColor: theme.palette.grey[800], // Dark thumb color
                                border: `2px solid ${theme.palette.common.white}`, // White border for thumb
                                '&:hover': {
                                    backgroundColor: theme.palette.grey[700], // Slightly lighter thumb color on hover
                                },
                            },
                            '& .MuiSlider-track': {
                                backgroundColor: theme.palette.grey[300], // Light grey track
                            },
                            '& .MuiSlider-rail': {
                                backgroundColor: theme.palette.grey[400], // Even lighter rail
                            },
                            mt: 5, // Add margin-top to move the slider further down
                        }}
                    />
                </Box>
            </Modal>
        </Box>
    )
}
export default GrowShrinkComponent
